import React, { useCallback, useEffect, useState } from 'react';

import { mergeFilteredViews } from '../../../shared/foreground/stateUpdaters/persistentStateUpdaters/filteredView';
import { createToast } from '../../../shared/foreground/toasts.platform';
import { FilteredView } from '../../../shared/types';
import { Dialog } from './Dialog';
import styles from './Dialog.module.css';

export const MergeViewsDialog = ({ isOpen, setIsOpen, selectedIds, setSelectedIds, views }: {isOpen: boolean; setIsOpen: (v: boolean) => void; selectedIds: string[]; setSelectedIds: (v: string[]) => void; views: FilteredView[];}) => {
  const [newViewName, setNewViewName] = useState('');

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const onConfirm = useCallback(() => {
    if (!newViewName) {
      createToast({
        content: 'Please, enter a view name',
        category: 'error',
      });
      return;
    }

    mergeFilteredViews({ viewIds: selectedIds, viewName: newViewName, options: { userInteraction: 'click' } });

    onClose();
    setSelectedIds([]);
  }, [newViewName, selectedIds, onClose, setSelectedIds]);

  useEffect(() => {
    const firstTagId = selectedIds[0];
    const firstView = views.find((view) => view.id === firstTagId);

    if (firstView) {
      setNewViewName(firstView.name);
    }
  }, [views, selectedIds]);

  if (isOpen) {
    return <div>
      <Dialog
        id="merge-views"
        title={`Merge ${selectedIds.length} views?`}
        actionTitle="Merge views"
        cancelTitle="Cancel"
        action={onConfirm}
        cancelAction={onClose}>
          <div className={styles.inputWrapper}>
            <p>Name for merged views</p>
            <input autoFocus aria-label="view name" value={newViewName} onChange={(e) => setNewViewName(e.target.value)} placeholder="View name" type="text" autoComplete="off" />
          </div>
      </Dialog>
    </div>;
  }

  return null;
};
