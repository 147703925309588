import React from 'react';

import { Dialog } from './Dialog';


export const DeleteViewsDialog = ({ isOpen, onConfirm, onCancel, count }: {isOpen: boolean; onConfirm: () => void; onCancel: () => void; count: number;}) => {
  if (isOpen) {
    const viewOrViews = count > 1 ? 'Views' : 'View';

    return <div>
      <Dialog
        id="delete-views"
        title={`Delete ${count} ${viewOrViews}?`}
        subtitle={`Are you sure you want to delete the selected ${viewOrViews}? Only the Views will be deleted. None of the documents filtered by those Views will be deleted.`}
        actionTitle={`Delete ${count} ${viewOrViews}`}
        cancelTitle="Cancel"
        redActionButton
        action={onConfirm}
        cancelAction={onCancel} />
      </div>;
  }

  return null;
};
