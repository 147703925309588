import React from 'react';

import Icon from './Icon';

export default function StrokePinIcon({ className = '', text = 'StrokePin' }: { className?: string; text?: string; }): JSX.Element {
  return <Icon text={text}>
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="black" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.25 2.58334C5.37579 2.58334 4.66667 3.29246 4.66667 4.16668V4.55418C4.66667 5.08223 4.93076 5.57804 5.37199 5.87189L7.16667 7.06811V8.78076L4.91041 10.3927C4.49491 10.6889 4.25 11.1667 4.25 11.675C4.25 12.5451 4.95495 13.25 5.825 13.25H9.25V16.6667C9.25 17.0809 9.58579 17.4167 10 17.4167C10.4142 17.4167 10.75 17.0809 10.75 16.6667V13.25H14.175C15.045 13.25 15.75 12.5451 15.75 11.675C15.75 11.1667 15.5048 10.6888 15.0894 10.3926L12.8333 8.78076V7.06811L14.6296 5.87086C15.0687 5.57707 15.3333 5.08358 15.3333 4.55418V4.16668C15.3333 3.29246 14.6242 2.58334 13.75 2.58334H6.25ZM10.004 11.75L10 11.75L9.99599 11.75H5.825C5.78338 11.75 5.75 11.7166 5.75 11.675C5.75 11.6501 5.76241 11.6274 5.78182 11.6136L8.35265 9.77694C8.54972 9.63615 8.66667 9.40887 8.66667 9.16668V6.66668C8.66667 6.41589 8.54132 6.1817 8.33264 6.0426L6.20301 4.62313C6.18118 4.6086 6.16667 4.5826 6.16667 4.55418V4.16668C6.16667 4.12089 6.20421 4.08334 6.25 4.08334H13.75C13.7958 4.08334 13.8333 4.12089 13.8333 4.16668V4.55418C13.8333 4.58135 13.8195 4.60793 13.7954 4.62416L11.6674 6.0426C11.4587 6.1817 11.3333 6.41589 11.3333 6.66668V9.16668C11.3333 9.40887 11.4503 9.63615 11.6473 9.77694L14.2189 11.6141C14.2383 11.6279 14.25 11.6501 14.25 11.675C14.25 11.7166 14.2166 11.75 14.175 11.75H10.004Z" fill="none" />
    </svg>
  </Icon>;
}
